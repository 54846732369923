/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import { rem } from 'polished';

import colors from './colors';
import radius from './radius';
import spacing from './spacing';

const control = {
  border: {
    color: {
      base: colors.light[600],
      hover: colors.primary[500],
    },
    size: { base: 1 },
    type: 'solid',
  },
  icon: {
    color: {
      base: colors.primary,
    },
    size: {
      base: 16,
    },
  },
  size: {
    height: {
      base: rem(40),
    },
  },
  padding: {
    x: {
      base: spacing.s,
      small: spacing.xs,
      icon: spacing.xl,
      iconSmall: spacing.xl,
    },
    y: {
      base: 0,
      small: 0,
    },
  },
  radius: {
    base: radius.s,
  },
};

export default control;
