import { rem, rgba } from 'polished';
import styled, {
  css,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';
import tw from 'twin.macro';

import down from '../../../images/icons/down.svg';
import {
  colors,
  control,
  DefaultThemeProps,
  StatusProp,
  transition,
} from '../../../styles/theme';

const getStatusStyle = ({ status }: StatusProp): FlattenSimpleInterpolation => {
  switch (status) {
    case 'error':
      return css`
        border-color: ${colors.danger[500]};
      `;
    case 'warning':
      return css`
        border-color: ${colors.warning[500]};
      `;
    default:
      return css`
        border-color: ${control.border.color.base};
      `;
  }
};

export const inputDisabledStyles = css`
  opacity: 0.5;
  pointer-events: none;
`;

export const getControlStyle = (
  props: ThemeProps<DefaultThemeProps> & StatusProp,
): FlattenSimpleInterpolation => css`
  ${getStatusStyle(props)};
  border-style: ${control.border.type};
  border-width: ${control.border.size.base}px;
  border-radius: ${control.radius.base};
  background-color: ${colors.neutral[100]};
  border-radius: ${control.radius.base};
  display: flex;
  transition: border-color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base},
    background-color ${transition.timing.base} ${transition.easing.base};
  &:not([type='checkbox']):focus,
  &:not([type='checkbox']):focus-within {
    outline: none;
    box-shadow: 0 0 ${rem(1)} ${rem(3)}
      ${rgba(control.border.color.hover, 0.125)};
  }
  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${control.border.color.hover};
    background-color: ${colors.light[300]};
  }
  &:disabled {
    ${inputDisabledStyles};
    border-color: ${control.border.color.base};
  }

  ::placeholder {
    color: ${colors.neutral[300]};
  }
`;

export const ControlSC = styled.div.attrs({
  className: 'px-4',
})`
  ${getControlStyle};
  display: block;
  width: 100%;
  line-height: 2.5rem;
`;

export const InputSC = styled(ControlSC).attrs({ as: 'input' })`
  &[type='checkbox'] {
    width: auto;
    ${tw`mr-4`}
  }
`;

export const FieldInputSC = styled.div`
  min-height: 2.5rem;
`;

export const SelectSC = styled(ControlSC).attrs({ as: 'select' })`
  padding-right: ${control.padding.x.base};
  background-image: url(${down});
  background-repeat: no-repeat, repeat;
  background-position: right ${control.padding.x.base} top 50%, 0 0;
  background-size: ${rem(12)} auto, 100%;
  option {
    font-weight: normal;
  }
  &:invalid {
    color: ${colors.neutral[300]};
  }
`;

export const TextareaSC = styled(ControlSC).attrs({ as: 'textarea' })``;

export default styled.div``;
