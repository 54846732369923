import { darken, lighten, rem } from 'polished';
import styled, { css, ThemeProps } from 'styled-components';

import {
  colors,
  DefaultThemeProps,
  font,
  radius,
  transition,
} from '../../../styles/theme';
import ButtonReset from '../ButtonReset';
import Link from '../Link';
import { ButtonProps } from './index';

export const ButtonText = styled.span``;

interface ButtonStyleProps extends ThemeProps<DefaultThemeProps>, ButtonProps {}

export const IsLoading = styled.div`
  position: absolute;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const buttonStatusStyles = ({ status }: ButtonStyleProps): any => {
  switch (status) {
    case 'neutral':
      return css`
        background: ${colors.light};
        color: ${colors.primary[700]};
        box-shadow: inset 0 0 0 1px ${colors.neutral[300]};
        &:hover {
          background: ${colors.primary[300]};
          color: ${darken(0.125, colors.primary[500])};
        }
        ${IsLoading} {
          background: ${colors.light};
        }
      `;
    case 'link':
      return css`
        background: transparent;
        color: ${colors.dark[500]};
        padding: 0 !important;
        min-height: auto !important;
        line-height: inherit; !important;
        font-weight: inherit;
        &:hover {
          background: transparent;
          color: ${colors.primary[400]};
        }
      `;
    case 'secondary':
      return css`
        background: ${colors.secondary[500]};
        color: ${colors.secondary[700]};
        &:hover {
          background: ${colors.secondary[400]};
          color: ${colors.secondary[700]};
        }
        ${IsLoading} {
          background: ${colors.secondary[500]};
        }
      `;
    case 'tertiary':
      return css`
        background: ${colors.tertiary[500]};
        color: ${colors.dark[500]};
        &:hover {
          background: ${colors.tertiary[400]};
          color: ${colors.primary[500]};
        }
        ${IsLoading} {
          background: ${colors.tertiary[400]};
        }
      `;
    case 'light':
      return css`
        background: ${colors.light};
        color: ${colors.primary[400]};
        box-shadow: inset 0 0 0 1px ${colors.dark[300]};
        transition: all ${transition.timing.base} ${transition.easing.base};
        &:hover {
          background: ${lighten(0.05, colors.light[500])};
          color: ${colors.primary[500]};
          box-shadow: inset 0 0 0 1px ${colors.light[400]};
        }
        ${IsLoading} {
          background: ${colors.light[500]};
        }
      `;
    case 'danger':
      return css`
        background: ${colors.danger[500]};
        color: ${colors.light};
        &:hover {
          background: ${colors.danger[700]};
          color: ${colors.light};
        }
        ${IsLoading} {
          background: ${colors.danger[500]};
        }
      `;
    default:
      return css`
        background: ${colors.primary[500]};
        color: ${colors.light[300]};
        &:hover {
          background: ${colors.primary[800]};
          color: ${colors.light};
        }
        ${IsLoading} {
          background: ${colors.primary[700]};
        }
      `;
  }
};

const buttonSizeProps = ({ large, size, square }: ButtonStyleProps): any => {
  switch (size) {
    case 'small':
      return css`
        font-size: ${rem(font.size.xs)};
        min-height: ${rem(32)};
        padding: ${rem(4)} ${square ? 0 : rem(12)};
        min-width: ${square ? rem(32) : 'initial'};
        border-radius: ${radius.xs};
        > * + * {
          margin-left: ${rem(6)};
        }
        svg {
          height: ${rem(14)};
          width: ${rem(14)};
        }
      `;
    case 'big':
      return css`
        min-height: ${rem(48)};
        padding: ${rem(8)} ${square ? 0 : rem(24)};
        min-width: ${square ? rem(48) : 'initial'};
        border-radius: ${radius.s};
        ${ButtonText} {
          min-width: ${large ? rem(100) : 'initial'};
        }
        > * + * {
          margin-left: ${rem(12)};
        }
        svg {
          height: ${rem(16)};
          width: ${rem(16)};
        }
      `;
    default:
      return css`
        min-height: ${rem(40)};
        padding: ${rem(11)} ${square ? 0 : rem(16)};
        min-width: ${square ? rem(40) : 'initial'};
        border-radius: ${radius.s};
        ${ButtonText} {
          min-width: ${large ? rem(100) : 'initial'};
        }
        > * + * {
          margin-left: ${rem(12)};
        }
      `;
  }
};

export const ButtonSC = styled(ButtonReset)<ButtonStyleProps>`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: ${font.weight.medium};
  text-align: center;
  transition: background ${transition.timing.base} ${transition.easing.base},
    color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base};
  opacity: ${(props: ButtonProps): number => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props: ButtonProps): string =>
    props.disabled ? 'none !important' : 'auto'};

  ${(props: ButtonStyleProps): any => buttonStatusStyles(props)};
  ${(props: ButtonStyleProps): any => buttonSizeProps(props)};

  &:focus {
    outline: none;
  }

  @media screen and (-ms-high-contrast: active) {
    border: 2px solid currentcolor;
  }
`;

ButtonSC.defaultProps = {
  large: false,
  size: 'base',
  square: false,
};

export const LinkEl = styled(ButtonSC).attrs({
  as: Link,
})`
  text-decoration: none;
`;
