/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
module.exports = {
  light: {
    50: '#ffffff',
    100: '#fefefe',
    200: '#fdfdfd',
    300: '#fcfcfc',
    400: '#fafafa',
    500: '#f8f8f8',
    600: '#dfdfdf',
    700: '#bababa',
    800: '#959595',
    900: '#7a7a7a',
  },
  dark: {
    100: '#76777A',
    200: '#5F6164',
    300: '#494A4D',
    400: '#323437',
    500: '#1B1D21',
    600: '#17181C',
    700: '#121316',
    800: '#0E0F11',
    900: '#090A0B',
  },
  neutral: {
    50: '#f9f9fa',
    100: '#f3f3f5',
    200: '#e0e1e7',
    300: '#cdcfd9',
    400: '#a8acbc',
    500: '#83889f',
    600: '#767a8f',
    700: '#626677',
    800: '#4f525f',
    900: '#40434e',
  },
  primary: {
    50: '#f8f5fc',
    100: '#f0ebf8',
    200: '#daceee',
    300: '#c3b1e3',
    400: '#9776ce',
    500: '#6a3bb9',
    600: '#5f35a7',
    700: '#502c8b',
    800: '#40236f',
    900: '#341d5b',
  },
  secondary: {
    50: '#fafcf7',
    100: '#f6faef',
    200: '#e8f2d6',
    300: '#daebbe',
    400: '#bedb8d',
    500: '#a2cc5c',
    600: '#92b853',
    700: '#7a9945',
    800: '#617a37',
    900: '#4f642d',
  },
  tertiary: {
    50: '#fffafc',
    100: '#fff6f9',
    200: '#ffe8ef',
    300: '#ffdae5',
    400: '#ffbed2',
    500: '#ffa2bf',
    600: '#e692ac',
    700: '#bf7a8f',
    800: '#996173',
    900: '#7d4f5e',
  },
  success: {
    100: '#EFFBCE',
    200: '#DBF89F',
    300: '#BBEB6C',
    400: '#9AD846',
    500: '#6EBF13',
    600: '#56A40D',
    700: '#418909',
    800: '#2E6E06',
    900: '#215B03',
  },
  info: {
    100: '#CCFDF8',
    200: '#9AFCFA',
    300: '#67F0F8',
    400: '#40DBF1',
    500: '#06BBE8',
    600: '#0492C7',
    700: '#036DA7',
    800: '#014E86',
    900: '#01386F',
  },
  warning: {
    100: '#FFF4CC',
    200: '#FFE799',
    300: '#FFD666',
    400: '#FFC53F',
    500: '#FFAA00',
    600: '#DB8A00',
    700: '#B76D00',
    800: '#935300',
    900: '#7A4000',
  },
  danger: {
    100: '#FFE6D3',
    200: '#FFC7A8',
    300: '#FFA07C',
    400: '#FF7C5C',
    500: '#FF3F26',
    600: '#DB221B',
    700: '#B7131A',
    800: '#930C1D',
    900: '#7A071E',
  },
  logo: {
    primary: '#2c2661',
    secondary: '#b4c348',
  },
  transparent: 'transparent',
  print: {
    base: '#000',
    light: '#ddd',
  },
};
