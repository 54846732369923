import classNames from 'classnames';
import { rem } from 'polished';
import styled, {
  css,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';

import { DefaultThemeProps, radius, StatusProp } from '../../styles/theme';

export const ColumnSC = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ColumnsSC = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    margin-right: 1.5rem;
  }

  > div:last-child {
    margin-right: 0;
  }
`;

const getStatusStyles = ({ status }: StatusProp): any => {
  switch (status) {
    case 'error':
      return css`
        color: ${(props): string => props.theme.colors.danger[500]};
      `;
    case 'warning':
      return css`
        color: ${(props): string => props.theme.colors.warning[500]};
      `;
    default:
      return css`
        color: ${(props): string => props.theme.colors.neutral[500]};
      `;
  }
};

export const FieldMessageSC = styled.div<
  ThemeProps<DefaultThemeProps> & StatusProp
>`
  transform: translateY(${rem(4)});
  font-size: ${(props: ThemeProps<DefaultThemeProps>): string =>
    rem(props.theme.font.size.xs)};
  ${(props: StatusProp): FlattenSimpleInterpolation => getStatusStyles(props)};
`;

export const GroupSC = styled.fieldset.attrs(props => ({
  className: classNames(props.className, 'p-3 mb-3'),
}))<ThemeProps<DefaultThemeProps>>`
  border: 1px solid
    ${(props: ThemeProps<DefaultThemeProps>): string =>
      props.theme.colors.light[700]};
  border-radius: ${radius.s};

  > p:first-child {
  }
`;

export const GroupDescriptionSC = styled.p.attrs(props => ({
  className: classNames(props.className, 'mb-4 italic text-neutral-400'),
}))``;
