import 'moment/locale/fr';
import './src/styles/utilities.css';
import './src/styles/prism.css';

// import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import { FormidableProvider } from 'dataformjs';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Translation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { ButtonSC } from './src/components/base/Button/styles';
import {
  getControlStyle,
  InputSC,
  SelectSC,
  TextareaSC,
} from './src/components/base/Field/style';
import {
  ColumnSC,
  ColumnsSC,
  FieldMessageSC,
  GroupDescriptionSC,
  GroupSC,
} from './src/components/base/styles';
import i18n from './src/i18n';
import theme from './src/styles/theme';

const WrapRootElement = ({ element }) => {
  if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    window.navigator.serviceWorker
      .getRegistrations()
      .then(registrations => registrations.forEach(r => r.unregister()))
      .catch(error => {
        console.error(error);
      });
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <ThemeProvider theme={theme}>
      <Translation i18n={i18n}>
        {t => (
          <FormidableProvider
            getControlStyle={getControlStyle}
            t={t}
            theme={{
              button: ButtonSC,
              column: ColumnSC,
              columns: ColumnsSC,
              fieldMessage: FieldMessageSC,
              group: GroupSC,
              groupDescription: GroupDescriptionSC,
              input: InputSC,
              select: SelectSC,
              textarea: TextareaSC,
            }}
          >
            {element}
          </FormidableProvider>
        )}
      </Translation>
    </ThemeProvider>
  );
};

WrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = WrapRootElement;
