/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import { em, rem, rgba } from 'polished';
import { DefaultTheme } from 'styled-components';

import colors from './colors';
import control from './control';
import font from './font';
import radius from './radius';
import spacing from './spacing';

export interface StatusProp {
  status?:
    | 'error'
    | 'light'
    | 'dark'
    | 'link'
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger';
}

export interface DefaultThemeProps extends DefaultTheme {
  borderRadius: string;
  colors: {
    light: {
      50?: string;
      100?: string;
      200?: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    dark: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    neutral: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    primary: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    secondary: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    tertiary: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    success: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    info: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    warning: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    danger: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    logo: {
      primary: string;
      secondary: string;
    };
    print: {
      base: string;
      light: string;
    };
  };
  control: any;
  font: any;
}

const theme: DefaultThemeProps = {
  borderRadius: '5px',
  colors,
  control,
  font,
};

export const lineHeight = {
  xs: 1,
  s: 1.15,
  m: 1.25,
  l: 1.5,
  xl: 1.625,
};

export const transition = {
  timing: {
    base: '0.3s',
    fast: '0.15s',
  },
  easing: {
    base: 'ease-in-out',
  },
};

export const gutter = {
  size: {
    xxxl: 48,
    xxl: 40,
    xl: 32,
    l: 16,
    m: 8,
  },
};

export const container = {
  size: {
    m: 1000,
  },
};

export const header = {
  height: {
    base: 80,
    small: 64,
  },
};

export const breakpoint = {
  smartphone: 480,
  tablet: 768,
  desktop: 1024,
  bigDesktop: 1300,
  ultraWide: 2000,
  mainNav: 900,
};

/**
 * mqUp
 *
 * @param {number} size
 * @returns {string}
 */

export const mediaQueryMin = (size: number): string =>
  `@media (min-width: ${em(size)})`;
/**
 * mqDown
 *
 * @param {number} size
 * @returns {string}
 */
export const mediaQueryMax = (size: number): string =>
  `@media (max-width: ${em(size + -1)})`;

export const boxShadow = {
  alternate: `0 ${rem(3)} ${rem(16)} 0 ${rgba(colors.primary[700], 0.05)}`,
  base: `0 ${rem(3)} ${rem(16)} 0 ${rgba(colors.dark[700], 0.05)}`,
  big: `0 ${rem(3)} ${rem(16)} 0 ${rgba(colors.dark[700], 0.1)}`,
};

export default theme;
export { colors, control, font, radius, spacing };
