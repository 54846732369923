/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
const font = {
  family: {
    base:
      '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,' +
      '"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  weight: {
    base: 400,
    medium: 600,
    bold: 700,
  },
  size: {
    xs: 12,
    s: 14,
    m: 16,
    l: 18,
    xl: 20,
    xxl: 24,
    xxxl: 28,
  },
};

export default font;
