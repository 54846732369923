import { Link as GatsbyLink } from 'gatsby';
import React, { ReactNode } from 'react';

const Link = ({
  children,
  className,
  partiallyActive = false,
  title,
  to,
}: {
  children: ReactNode;
  className?: string;
  partiallyActive?: boolean;
  title?: string;
  to: string;
}): JSX.Element => {
  const internal = /^\/(?!\/)/.test(to);
  if (internal) {
    return (
      <GatsbyLink
        activeClassName="is-active"
        className={className}
        partiallyActive={partiallyActive}
        to={to}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a
      className={className}
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      title={title}
    >
      {children}
    </a>
  );
};

export default Link;
